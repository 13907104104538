<template>
  <ul class="menu-nav">
    <router-link to="/general"
                 v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]">
        <a :href="href"
           class="menu-link"
           @click="navigate">
          <span class="menu-text"> {{ $ml.get('general') }} </span>
        </a>
      </li>
    </router-link>

    <router-link :disabled="!userVerification.is_email_validated"
                 to="/payment"
                 v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]">
        <a :href="href"
           class="menu-link"
           @click="navigate">
          <span class="menu-text"> {{ $ml.get('payment') }} </span>
        </a>
      </li>
    </router-link>

    <router-link :disabled="!userVerification.is_email_validated"
                 to="/support"
                 v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]">
        <a :href="href"
           class="menu-link"
           @click="navigate">
          <span class="menu-text">{{ $ml.get('support') }}</span>
        </a>
      </li>
    </router-link>

    <router-link :disabled="!userVerification.is_email_validated"
                 v-if="false"
                 to="/team-management"
                 v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]">
        <a :href="href"
           class="menu-link"
           @click="navigate">
          <span class="menu-text">{{ $ml.get('teamManagement') }}</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'KTMenu',
  computed: {
    ...mapGetters(['userVerification']),
  },
  methods: {
    // hasActiveChildren(match) {
    //   return this.$route["path"].indexOf(match) !== -1;
    // }
  },
}
</script>
