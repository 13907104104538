<template>
  <div class="d-flex flex-column flex-root"
       v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper"
           class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader :title="pageTitle"></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div id="kt_content"
             class="content d-flex flex-column flex-column-fluid">
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader v-if="subheaderDisplay"
                       :breadcrumbs="breadcrumbs"
                       :title="pageTitle" />

          <KTCustomSubheader />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }">
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <KTScrollTop />
    <CustomerAgentModal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import KTAside from '@/view/layout/aside/Aside.vue'
import KTHeader from '@/view/layout/header/Header.vue'
import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue'
import HtmlClass from '@/core/services/htmlclass.service'
import KTSubheader from '@/view/layout/subheader/Subheader.vue'
import KTCustomSubheader from '@/view/layout/subheader/CustomSubHeader.vue'
import KTScrollTop from '@/view/layout/extras/ScrollTop'
import CustomerAgentModal from '@/components/CustomerAgentModal'

export default {
  name: 'Layout',
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTSubheader,
    KTCustomSubheader,
    KTScrollTop,
    CustomerAgentModal,
  },
  beforeMount() {
    // initialize html element classes
    HtmlClass.init(this.layoutConfig())
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: 'login' })
    }
  },
  methods: {},
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'breadcrumbs',
      'pageTitle',
      'layoutConfig',
    ]),

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig('content.width') === 'fluid'
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display')
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig('subheader.display')
    },
  },
}
</script>
