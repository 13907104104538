
<template>
  <img src="/image/logo.png"
       alt="Logo">
</template>

<script>
export default {
  name: 'Logo',
}
</script>