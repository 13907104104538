<template>
  <div id="kt_header_mobile"
       class="header-mobile align-items-center"
       :class="headerClasses">
    <!--begin::Logo-->
    <a href="/">
      <img src="/image/side-logo.png"
           alt="Logo"
           class="side-logo">
    </a>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin::Topbar Support Toggle-->
      <button class="btn btn-hover-text-primary p-0 ml-2"
              v-b-modal.modal-customer-agent>
        <i class="fas fa-comments text-white"></i>
      </button>
      <!--end::Topbar Support Toggle-->
      <!--begin::Topbar Mobile Toggle-->
      <button class="btn btn-hover-text-primary p-0 ml-2"
              id="kt_header_mobile_topbar_toggle"
              ref="kt_header_mobile_topbar_toggle">
        <i class="fas fa-user text-white"></i>
      </button>
      <!--end::Topbar Mobile Toggle-->
      <!--begin::Aside Mobile Toggle-->
      <button class="btn p-0 ml-3"
              id="kt_aside_mobile_toggle">
        <i class="fas fa-bars text-white"></i>
      </button>
      <!--end::Aside Mobile Toggle-->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import KTLayoutHeaderTopbar from '@/assets/js/layout/base/header-topbar.js'
import Logo from '@/components/Logo'

export default {
  name: 'KTHeaderMobile',
  mounted() {
    // Init Header Topbar For Mobile Mode
    setTimeout(() => {
      KTLayoutHeaderTopbar.init(this.$refs['kt_header_mobile_topbar_toggle'])
    }, 100)
  },
  computed: {
    ...mapGetters(['getClasses']),

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses('header_mobile')
      if (typeof classes !== 'undefined') {
        return classes.join(' ')
      }
      return null
    },
  },
  components: {
    Logo,
  },
}
</script>

<style lang="scss" scoped>
#kt_header_mobile {
  .side-logo {
    height: 27px;
  }
}
</style>