var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aside-menu"},[_vm._m(0),_c('p',{staticClass:"font-size-md mt-5",staticStyle:{"color":"#4A4B68","padding":"3px 25px"}},[_vm._v(" HESABIM ")]),_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/general"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-tachometer-alt menu-icon menu-icon-white"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$ml.get('general')))])])])]}}])}),_c('router-link',{attrs:{"to":"/payment","disabled":!_vm.userVerification.is_email_validated},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-money-bill-wave menu-icon menu-icon-white"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$ml.get('payment')))])])])]}}])}),_c('router-link',{attrs:{"to":"/support","disabled":!_vm.userVerification.is_email_validated},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-headset menu-icon menu-icon-white"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$ml.get('support')))])])])]}}])}),(false)?_c('li',{staticClass:"menu-spacer"}):_vm._e(),(false)?_c('router-link',{attrs:{"to":"/team-management","disabled":!_vm.userVerification.is_email_validated},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-user menu-icon menu-icon-white"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$ml.get('teamManagement')))])])])]}}],null,false,2761575746)}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn d-md-none close-button",attrs:{"id":"kt_aside_close_btn"}},[_c('i',{staticClass:"fas fa-times text-white"})])
}]

export { render, staticRenderFns }