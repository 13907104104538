<template>
  <div class="aside-menu">
    <button class="btn d-md-none close-button"
            id="kt_aside_close_btn">
      <i class="fas fa-times text-white"></i>
    </button>
    <p class="font-size-md mt-5"
       style="color: #4A4B68; padding: 3px 25px;">
      HESABIM
    </p>

    <ul class="menu-nav">
      <router-link to="/general"
                   v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]">
          <a :href="href"
             class="menu-link"
             @click="navigate">
            <!-- <i class="menu-icon flaticon2-library"></i> -->
            <i class="fas fa-tachometer-alt menu-icon menu-icon-white"></i>
            <span class="menu-text">{{ $ml.get('general') }}</span>
          </a>
        </li>
      </router-link>

      <router-link to="/payment"
                   :disabled="!userVerification.is_email_validated"
                   v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]">
          <a :href="href"
             class="menu-link"
             @click="navigate">
            <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
            <i class="fas fa-money-bill-wave menu-icon menu-icon-white"></i>
            <span class="menu-text">{{ $ml.get('payment') }}</span>
          </a>
        </li>
      </router-link>

      <router-link to="/support"
                   :disabled="!userVerification.is_email_validated"
                   v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]">
          <a :href="href"
             class="menu-link"
             @click="navigate">
            <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
            <i class="fas fa-headset menu-icon menu-icon-white"></i>
            <span class="menu-text">{{ $ml.get('support') }}</span>
          </a>
        </li>
      </router-link>

      <li class="menu-spacer"
          v-if="false"></li>

      <router-link to="/team-management"
                   v-if="false"
                   :disabled="!userVerification.is_email_validated"
                   v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]">
          <a :href="href"
             class="menu-link"
             @click="navigate">
            <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
            <i class="fas fa-user menu-icon menu-icon-white"></i>
            <span class="menu-text">{{ $ml.get('teamManagement') }}</span>
          </a>
        </li>
      </router-link>

      <!-- <li aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="{ 'menu-item-open': hasActiveChildren('compute') }">
      <a href="#"
         class="menu-link menu-toggle">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Compute</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu"
           style="display: none; overflow: hidden;">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true"
              class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Compute</span>
            </span>
          </li>

          <li aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu">
            <a href="#"
               class="menu-link menu-toggle">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Servers</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu">
              <span class="menu-arrow"></span>
              <ul class="menu-subnav">
                <li aria-haspopup="true"
                    class="menu-item menu-item-parent">
                  <span class="menu-link">
                    <span class="menu-text"></span>
                  </span>
                </li>

                <router-link to="/compute/virtual-machines"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]">
                    <a :href="href"
                       class="menu-link"
                       @click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Virtual Machines</span>
                    </a>
                  </li>
                </router-link>

                <router-link to="/compute/virtual-disks"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]">
                    <a :href="href"
                       class="menu-link"
                       @click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Virtual Disks</span>
                    </a>
                  </li>
                </router-link>

                <router-link to="/compute/virtual-network-cards"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]">
                    <a :href="href"
                       class="menu-link"
                       @click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Virt. Network Cards</span>
                    </a>
                  </li>
                </router-link>

                <router-link to="/compute/ip-management"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]">
                    <a :href="href"
                       class="menu-link"
                       @click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">IP</span>
                    </a>
                  </li>
                </router-link>

                <router-link to="/compute/templates"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]">
                    <a :href="href"
                       class="menu-link"
                       @click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Templates</span>
                    </a>
                  </li>
                </router-link>

              </ul>
            </div>
          </li>

          <li aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu">
            <a href="#"
               class="menu-link menu-toggle">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Virtual Private DC</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu">
              <span class="menu-arrow"></span>
              <ul class="menu-subnav">
                <li aria-haspopup="true"
                    class="menu-item menu-item-parent">
                  <span class="menu-link">
                    <span class="menu-text"></span>
                  </span>
                </li>

                <router-link to="/compute/private-domain"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]">
                    <a :href="href"
                       class="menu-link"
                       @click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Private Domain</span>
                    </a>
                  </li>
                </router-link>

                <router-link to="/compute/private-zones"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]">
                    <a :href="href"
                       class="menu-link"
                       @click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Virtual Zones (Networks)</span>
                    </a>
                  </li>
                </router-link>

                <router-link to="/compute/net-gateways"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]">
                    <a :href="href"
                       class="menu-link"
                       @click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">NetGateway</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>

        </ul>
      </div>
    </li> -->
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'KTMenu',
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1
    },
  },
  computed: {
    ...mapGetters(['userVerification']),
  },
  components: {},
}
</script>

<style lang="scss" scoped>
.aside-menu {
  .close-button {
    padding: 8px 18px 0 0;
    margin: 0;
    text-align: right;
    width: 100%;
    i {
      font-size: 18px;
    }
  }

  .menu-spacer {
    background: rgba(136, 140, 159, 0.3);
    height: 1px;
    margin: 8px 20px;
  }
}
</style>