<template>
  <div>
    <div class="support-subheader d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap mr-4">

      <div class="d-flex align-items-center">
        <span class="title">{{$ml.get('support')}}</span>
        <hr class="filter-divider-custom" />
        <div class="form-search-input">
          <v-text-field class="my-0 font-size-sm"
                        :placeholder="$ml.get('searchForSupport')"
                        prepend-icon="search"
                        v-model="search"
                        @blur="updateList"
                        @keyup="searchKeyup"
                        single-line
                        dense
                        flat
                        solo
                        hide-details></v-text-field>
        </div>
      </div>
      <div class="d-flex">
        <b-button variant="primary"
                  class="w-mb-100 rounded-sm btn-sm"
                  @click="openDetail">{{ $ml.get('openTicket') }}</b-button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from '@/event-bus'

export default {
  name: 'SupportSubHeader',
  data() {
    return {
      search: '',
      showDetail: false,
    }
  },
  computed: {
    ...mapGetters(['customHeaderParams']),
  },
  methods: {
    openDetail() {
      EventBus.$emit('supportTicketDetailOpen', true)
    },
    searchKeyup(e) {
      if (e.keyCode === 13) {
        this.updateList()
      }
    },
    updateList() {
      EventBus.$emit('supportSearch', this.search)
    },
  },
}
</script>

<style lang="scss" scoped>
.support-subheader {
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #434349;
  }
}
</style>