<template>
  <!-- begin:: Aside -->
  <div class="brand flex-column-auto"
       id="kt_brand"
       ref="kt_brand">
    <div class="brand-logo">
      <router-link to="/">
        <Logo />
      </router-link>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<script>
import Logo from '@/components/Logo'

export default {
  name: 'KTBrand',
  components: {
    Logo,
  },
}
</script>
