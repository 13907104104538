<template>
  <div>
    <div class="subheader py-2 py-lg-4 position-sticky custom-subheader"
         :class="subheaderClasses"
         id="kt_subheader"
         v-if="customHeaderParams.showComponentName">
      <div :class="{ 'container-fluid': widthFluid, container: !widthFluid }">
        <component :is="customHeaderParams.showComponentName" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SubHeaderMenu from '@/view/layout/subheader/custom/SubHeaderMenu'
import { CLEAR_CUSTOMHEADERPARAMS } from '@/core/services/store/customsubheader.module'
import SupportSubHeader from '@/view/layout/subheader/custom/SupportSubHeader'

export default {
  name: 'KTCustomSubheader',
  computed: {
    ...mapGetters(['layoutConfig', 'customHeaderParams']),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig('subheader.width') === 'fluid'
    },

    subheaderClasses() {
      const classes = []
      const style = this.layoutConfig('subheader.style')
      if (style) {
        classes.push(style)

        if (style === 'solid') {
          classes.push('bg-white')
        }

        if (this.layoutConfig('subheader.fixed')) {
          classes.push('border-top')
        }
      }
      return classes.join(' ')
    },
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch(CLEAR_CUSTOMHEADERPARAMS)
    },
  },
  components: {
    SubHeaderMenu,
    SupportSubHeader,
  },
}
</script>

<style lang="scss">
.custom-subheader {
  margin-top: -25px !important;
  margin-bottom: 25px !important;
}
</style>