<template>
  <div class="custom-subheader-menu d-flex align-items-center flex-wrap flex-sm-nowrap mr-4">
    <div v-for="(item, index) in  customHeaderParams.menuList"
         :key="index">
      <span class="title mr-1"
            v-if="index+1< customHeaderParams.menuList.length">
        <router-link :to="item.toLink?item.toLink:'#'">{{ item.isML ?  $ml.get(item.value): item.value }}</router-link> >
      </span>
      <span class="subTitle"
            v-else> {{ item.isML ?  $ml.get(item.value): item.value }} </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SubHeaderMenu',
  computed: {
    ...mapGetters(['customHeaderParams']),
  },
}
</script>

<style lang="scss" scoped>
.custom-subheader-menu {
  .title {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #b5b5c3;
  }
  .subTitle {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
  }
  a {
    color: #b5b5c3 !important;
  }
}
</style>